import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyDTaGASuYL5ZEW5YUaJvOa3DN-7LSaXn8g",
  authDomain: "talkmeapp-e696c.firebaseapp.com",
  projectId: "talkmeapp-e696c",
  storageBucket: "talkmeapp-e696c.appspot.com",
  messagingSenderId: "375340415237",
  appId: "1:375340415237:web:d0551e6e27d341eb7f9f6c",
  measurementId: "G-Q5HNTEV6DH"
};

// init firebase
initializeApp(firebaseConfig)

// init firebase auth
const auth = getAuth()
auth.useDeviceLanguage()

export { auth }