<template>
  <div id="first-part">
    <div id="first-content" class="main-page-content-section" style="padding-bottom: 0;">
      <div style="width: 40% ; align-self: center; flex: 1">
        <p class="title">{{ pageTexts.it.firstContent.title }}</p>
        <br>
        <p>{{ pageTexts.it.firstContent.sentence1 }}</p>
        <br>
        <p>{{ pageTexts.it.firstContent.sentence2 }}</p>
        <br>
        <Button @click="handleTestYourAssistantButton()" class="cta" :label="pageTexts.it.selectPlanButtonText" />
        <!--
        <p id="last-sentence">{{ pageTexts.it.firstContent.sentence3 }}</p>
        -->
      </div>
      <div style="width: 60% ; min-width: 300px ; flex: 1; margin-top: auto ;">
        <img style="width: 100% ;" alt="Stirlo smiling face" src="@/assets/images/mrcall/hero/hero_home_page_stirlo.png"/>
      </div>
    </div>
  </div>
  <div id="second-part">
    <div class="main-page-content-section">
      <p class="title" style="text-align: center ;">{{ pageTexts.it.secondContent.title }}</p>
      <div id="second-content">
        <div style="width: 30% ; min-width: 200px ; align-self: center;">
          <img style="width: 90%;" alt="smiling lady" src="@/assets/images/mrcall/intro_home_page.png"/>
        </div>
        <div class="with-left-border" style="width: fit-content; align-self: center; flex: 1;">
          <p style="font-weight: bold;">{{ pageTexts.it.secondContent.sentence1 }}</p>
          <br>
          <p>{{ pageTexts.it.secondContent.sentence2 }}</p>
          <br>
          <p>{{ pageTexts.it.secondContent.sentence3 }}</p>
          <br>
          <p>
            <!-- {{ pageTexts.it.secondContent.sentence4 }} -->
            Puoi personalizzare e gestire l'assistente anche da <a href="https://play.google.com/store/apps/details?id=ai.mrcall.app">App Mobile Android</a>, e tra poco iPhone
          </p>
          <!--
          <br>
          <p>{{ pageTexts.it.secondContent.sentence5 }}</p>
          <br>
          -->
        </div>
      </div>
    </div>
  </div>
  <div id="features-part">
    <div class="main-page-content-section">
      <p class="title" style="text-align: center ;">{{ pageTexts.it.featuresContent.title }}</p>
      <div class="features-content">
        <div class="features-text">
          <ul>
            <li v-for="feature in pageTexts.it.featuresContent.items" style="margin-bottom: 0.3em;" :key="feature">
              <p class="feature-highligth">{{feature.highlighed}}</p> <p class="feature-normal">{{feature.normal}}</p>
            </li>
          </ul>
        </div>
        <div class="features-image">
          <img style="width: 100%;" alt="MrCall Logo for features" src="@/assets/images/mrcall/littleman/features.svg"/>
        </div>
      </div>

    </div>
  </div>
  <div id="third-part">
    <div class="main-page-content-section title-box">
      <p class="title" style="text-align: center ;">{{ pageTexts.it.thirdContent.title }}</p>
    </div>
    <div class="main-page-content-section">
      <div class="third-content">
        <div class="plan-card" v-for="plan in pageTexts.it.thirdContent.plans" :key="plan.title">
          <div class="center">
            <p class="plan-title">{{plan.title}}</p>
            <img style="padding-left: 1em; height: 3em" :src="plan.image" />
          </div>
          <br>
          <div v-for="subtitleSentence in plan.subtitle" :key="subtitleSentence">
            <p class="plan-subtitle-sentence">{{subtitleSentence}}</p>
          </div>
          <br>
          <p class="plan-description-title">{{plan.descriptionMain.title}}</p>
          <div style="padding-top: 1em; position: relative; bottom: 0;">
          </div>
          <ul>
            <li v-for="description in plan.descriptionMain.items" :key="description">
              <p class="plan-description-item">{{description}}</p>
            </li>
          </ul>
          <div v-if="plan.descriptionDiscover">
            <br>
            <p v-on:click="plan.descriptionDiscover.visible = !plan.descriptionDiscover.visible"
               class="more-information center">{{plan.descriptionDiscover.moreInfoSentence}}
              <i v-if="plan.descriptionDiscover.visible" class="pi pi-chevron-circle-down"></i>
              <i v-else class="pi pi-chevron-circle-right"></i>
            </p>
            <div v-show="plan.descriptionDiscover.visible">
              <br>
              <p class="plan-description-title">{{plan.descriptionDiscover.title}}</p>
              <ul>
                <li v-for="description in plan.descriptionDiscover.items" :key="description">
                  <p class="plan-description-item" :style="'font-weight: ' + description.fontWeight + ';'">{{description.value}}</p>
                </li>
              </ul>
              <br>
            </div>
            <div class="price-block">
              <p style="display:inline" class="price-currency">{{plan.pricing.currency}}</p>
              <p style="display:inline" class="price-number">{{plan.pricing.number}}</p>
              <p style="display:inline" class="price-periodicity">{{plan.pricing.periodicity}}</p>
            </div>
          </div>
          <div class="center" style="padding-top: 2em;">
            <Button @click="handleTestYourAssistantButton()" class="cta" :label="pageTexts.it.selectPlanButtonText" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="usecases-part">
    <div class="main-page-content-section">
      <p class="title center">{{ pageTexts.it.fourthSection.title }}</p>
      <div class="usecases-content">
        <div class="sample-business center" v-for="sampleBusiness in pageTexts.it.fourthSection.elements" :key="sampleBusiness">
          <img style="width: 70%" :src="sampleBusiness.image"/>
          <p style="padding-top: 1em; padding-bottom: 1em;" class="sample-business-title">{{sampleBusiness.title}}</p>
          <p class="sample-business-sentence" v-for="sentence in sampleBusiness.sentences" :key="sentence">
            {{sentence}}
          </p>
        </div>
      </div>
      <div class="video-content">
        <div class="center video-section-title">
          <p class="title">{{pageTexts.it.videContentSection.title}}</p>
        </div>
        <div class="center video-section-sequence">
          <!--
          <Carousel :value="pageTexts.it.videContentSection.videos"
                    :circular="true"
                    :autoplayInterval="10000"
                    :responsiveOptions="responsiveOptionsUsecasesVideos">
            <template  #item="videoItem" >
              <div class="center video-item">
                <iframe height="599" :src="videoItem.data.url" :title="videoItem.data.title" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </template>
          </Carousel>
          -->
          <div class="center video-item" v-for="videoItem in pageTexts.it.videContentSection.videos" :key="videoItem.url">
            <iframe height="599" :src="videoItem.url" :title="videoItem.title" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="testimonials-part">
    <div class="main-page-content-section center">
      <Carousel :value="pageTexts.it.testimonialsSection.elements"
                :circular="true"
                :autoplayInterval="10000"
                :responsiveOptions="responsiveOptionsTestimonials">
        <template #header>
          <p class="title">
            {{ pageTexts.it.testimonialsSection.titlePart1 }}
            <br>
            {{ pageTexts.it.testimonialsSection.titlePart2 }}
          </p>
        </template>
        <template #item="testimonialItem">
          <div class="witness center" style="flex-flow: initial; display: flex;">
            <img style="width: 20% ; height: auto; margin-bottom: auto;" :src="testimonialItem.data.image"/>
            <div class="center" style="width: 70%">
              <p class="witness-sentence" v-for="sentence in testimonialItem.data.sentences" :key="sentence">
                {{sentence}}
              </p>
              <div class="witness-section">
                <p class="witness-title">{{testimonialItem.data.title}}</p>
                <p class="witness-subtitle">{{testimonialItem.data.subtitle}}</p>
              </div>
            </div>
          </div>
        </template>
      </Carousel>
      <div>
        <img style="width: 4em;" src="@/assets/images/mrcall/littleman/testimonial.svg"/>
        <Button style="margin-top: 1em;" class="cta" @click="handleTestYourAssistantButton()"
                :label="pageTexts.it.testimonialsSection.callToActionText" />
        <!--
        <Button style="margin-top: 1em;" @click="gotoElement('#third-part')" :label="selectPlanButtonText" />
        -->
      </div>
    </div>
  </div>
  <div id="sixth-part">
    <div class="main-page-content-section">
      <div id="sixth-content">
        <div class="right" style="width: fit-content;  flex: 1;">
          <p style="font-weight: bold;">{{ pageTexts.it.sixthContent.title }}</p>
          <br>
          <br>
          <p>{{ pageTexts.it.sixthContent.sentence1 }}</p>
          <p>{{ pageTexts.it.sixthContent.sentence2 }}</p>
          <p>{{ pageTexts.it.sixthContent.sentence3 }}</p>
          <br>
        </div>
        <div class="with-left-border" style="width: 25% ; min-width: 200px ; align-self: center;">
          <img style="width: 100%;" src="@/assets/images/mrcall/mrcall_rescue.png"/>
        </div>
      </div>
      <div class="center">
        <Button @click="gotoElement('#footer')" :label="pageTexts.it.sixthContent.button" />
      </div>
    </div>
  </div>
  <div id="seventh-part">
    <div class="main-page-content-section">
      <p class="title center">{{ pageTexts.it.seventhContent.title }}</p>
      <div id="seventh-content">
        <div class="with-left-border" style="width: fit-content; align-self: center; flex: 1; margin-bottom: auto;">
          <p style="font-weight: bold;">{{ pageTexts.it.seventhContent.left.title }}</p>
          <br>
          <br>
          <p>{{ pageTexts.it.seventhContent.left.sentence1 }}</p>
          <p>{{ pageTexts.it.seventhContent.left.sentence2 }} <a :href="'mailto:' + pageTexts.it.seventhContent.left.mailTo">{{ pageTexts.it.seventhContent.left.mailTo }}</a></p>
          <p>{{ pageTexts.it.seventhContent.left.sentence3 }}</p>
          <br>
        </div>
        <div v-if="! isSmallWidthDevice()" style="width: 15% ; min-width: 80px ; align-self: center;">
          <img style="width: 100%; padding-left: 2em ; padding-right: 2em" src="@/assets/images/mrcall/littleman/support.svg"/>
        </div>
        <div class="with-left-border" style="width: fit-content; align-self: center; flex: 1; margin-bottom: auto;">
          <p style="font-weight: bold;">{{ pageTexts.it.seventhContent.right.title }}</p>
          <br>
          <br>
          <p>{{ pageTexts.it.seventhContent.right.sentence1 }}</p>
          <p>{{ pageTexts.it.seventhContent.right.sentence2 }}</p>
          <br>
        </div>
      </div>
      <div class="center">
        <Button @click="goToWebSiteRoute('/callbooking')" class="book-appointment" :label="pageTexts.it.seventhContent.button" />
      </div>
    </div>
  </div>
  <div id="footer">
    <FooterLarge/>
  </div>
</template>

<script>
import FooterLarge from "@/components/FooterLarge";

import privatePLanImage from "@/assets/images/mrcall/littleman/basic_plan.svg";
import businessPLanImage from "@/assets/images/mrcall/littleman/pro_plan.svg";
import businessPlusPLanImage from "@/assets/images/mrcall/littleman/full_plan.svg";

import individualImage from "@/assets/images/mrcall/whichassistant/individual.png";
import practicesImage from "@/assets/images/mrcall/whichassistant/preactices.png";
import restaurantsImage from "@/assets/images/mrcall/whichassistant/restaurants.png";

import testimonialIconImage from "@/assets/images/mrcall/littleman/testimonial.svg";
import router from "@/router";
import store from "@/store"
import {computed} from "vue";
import {useMeta} from "vue-meta";

export default {
  components: { FooterLarge },
  setup: () => {
    const metaData = {
      it: {
        title: "Risponditore automatico assistente digitale | MrCall",
        description: "La tua azienda ha bisogno di qualcuno che risponda sempre al telefono? Il risponditore telefonico del futuro è qui, prova Mrcall.",
        twitterTitle: "MrCall: Il risponditore automatico intelligente.",
        ogTitle: "MrCall: Il risponditore automatico intelligente.",
        locale: "it_IT",
        ogDescription: "La segreteria telefonica che risponde come una persona e ti invia le trascrizioni. " +
            "Si configura da sola, la personalizzi come vuoi. Immagina Alexa che chiede l'orario di prenotazione",
        twitterDescription: "La segreteria telefonica che risponde come una persona e ti invia le trascrizioni. " +
            "Si configura da sola, la personalizzi come vuoi. Immagina Alexa che chiede l'orario di prenotazione"
      }
    }

    useMeta({
      title: metaData.it.title,
      description: metaData.it.description,
      meta: [
        { charset: 'utf-8' },
        { property: 'http-equiv', content: metaData.it.description },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content:  'MrCall' },
        { property: 'og:url', content:  process.env.VUE_APP_SERVICE_BASE_URL},
        { property: 'og:locale', content: metaData.it.locale },
        { property: 'og:title', content: metaData.it.ogTitle },
        { property: 'og:description', content: metaData.it.ogDescription },
        { property: 'og:image:secure_url', content: process.env.VUE_APP_SERVICE_BASE_URL + "/assets/images/Screenshot_2022-05-15_at_18.41.42_1200x1200.png"},
        { property: 'twitter:title', content: metaData.it.twitterTitle },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:description', content: metaData.it.twitterDescription },
        { name: 'robots', content: 'index,follow' }
      ],
      link: [
        { rel: 'canonical', href: process.env.VUE_APP_SERVICE_BASE_URL }
      ]
    })
    return {
      metaData
    }
  },
  data: () => {
    const responsiveOptionsTestimonials = [
      {
        breakpoint: '8192px',
        numVisible: 3,
        numScroll: 1
      },
      {
        breakpoint: '960px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '600px',
        numVisible: 1,
        numScroll: 1
      }
    ]

    const responsiveOptionsUsecasesVideos = [
      {
        breakpoint: '8192px',
        numVisible: 3,
        numScroll: 1
      },
      {
        breakpoint: '1200px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '600px',
        numVisible: 1,
        numScroll: 1
      }
    ]

    const pageTexts = {
      it: {
        selectPlanButtonText: "Comincia gratis la tua prova",
        firstContent: {
          title: "Il risponditore automatico che ascolta, risponde, e trascrive come un operatore.", // Titolo in Montserrat Bold corpo 58 colore blu #0068FF: il blu del logo",
          sentence1: "Provalo subito: un mese è gratis!",
          sentence2: "",
          sentence3: "",
        },
        secondContent: {
          title: "Cosa è MrCall?",
          sentence1: "Un assistente digitale progettato da massimi esperti in intelligenza artificiale e interfacce uomo-macchina. Complesso dentro, semplice fuori.",
          sentence2: "Immagina Alexa o Siri che rispondono al telefono per te. Con MrCall puoi avere al tuo servizio lo stesso livello di intelligenza artificiale.",
          sentence3: "Non richiede nessun software: puoi dare ai tuoi clienti direttamente il numero MrCall oppure attivare l'inoltro di chiamata su quanti telefoni vuoi."
        },
        featuresContent:{
          title: "Cosa fa MrCall?",
          items: [
            {
              highlighed: "Si sincronizza",
              normal: "con i tuoi dati su Google Business, dicendo se sei aperto o chiuso"
            },
            {
              highlighed: "Risponde",
              normal: "a domande come 'Quando siete aperti?', 'Voglio prendere o cancellare un appuntamento' e molto altro"
            },
            {
              highlighed: "Ti manda notifiche",
              normal: "delle chiamate che ricevi, e puoi rispondere anche su WhatsApp"
            },
            {
              highlighed: "Riconosce",
              normal: "i tuoi contatti e li chiama per nome"
            },
            {
              highlighed: "Il motore di intelligenza artificiale",
              normal: "fa sì che risponda in maniera coerente alle richieste dei tuoi clienti"
            },
            {
              highlighed: "Ti invia una trascrizione",
              normal: "di ogni chiamata su mail e App"
            },
            {
              highlighed: "Ti fa personalizzare",
              normal: "i messaggi da usare con i tuoi clienti"
            },
            {
              highlighed: "Riconosce",
              normal: "le richieste di prenotazione, chiedendo giorno e ora della richiesta"
            }
          ]
        },
        thirdContent: {
          title: "Un mese di prova gratuita, senza impegno",
          plans: [
            {
              title: "Private",
              image: privatePLanImage,
              subtitle: [
                "Vuoi stupire chi ti chiama facendo rispondere un assistente basato su intelligenza artificiale?"
              ],
              descriptionMain: {
                title: "Con Private hai fino a 15 chiamate al mese gestite da MrCall",
                items: [
                ]
              },
              descriptionDiscover: {
                visible: false,
                title: "",
                moreInfoSentence: "e molto altro ancora",
                items: [
                  {value: "Un numero di telefono tuo", fontWeight: "normal"},
                  {value: "Risponde con messaggi personalizzati", fontWeight: "normal"},
                  {value: "Chiede il nome di chi ti chiama e lo associa al numero di telefono", fontWeight: "normal"},
                  {value: "Riconosce per nome i tuoi contatti", fontWeight: "normal"},
                  {value: "Ti invia la trascrizione e gli audio dei messaggi", fontWeight: "normal"}
                ]
              },
              pricing: {
                number: 5,
                currency: "€",
                periodicity: "al mese"
              }
            },
            {
              title: "Business",
              image: businessPLanImage,
              subtitle: [
                "Un Assistente completamente configurabile che risponde in maniera intelligente ai tuoi clienti"
              ],
              descriptionMain: {
                title: "Gestisci fino a 100 chiamate al mese. Tutte le funzionalità di Private...",
                items: [
                ]
              },
              descriptionDiscover: {
                visible: false,
                title: "",
                moreInfoSentence: "...e molto altro ancora",
                items: [
                  {value: "Personalizzazione di tutti i messaggi, diversi per orari di apertura o chiusura", fontWeight: "normal"},
                  {value: "Sincronizzazione con il tuo account Google Business e Calendar", fontWeight: "normal"},
                  {value: "Invio di SMS personalizzati ai clienti", fontWeight: "normal"},
                  {value: "Intelligenza Artificiale: comprensione di cosa dice il cliente e gestione automatica delle richieste", fontWeight: "normal"},
                  {value: "Intelligenza Artificiale: gestione delle richieste di prenotazione", fontWeight: "normal"},
                  {value: "A breve: inoltro della chiamata!", fontWeight: "normal"},
                ]
              },
              pricing: {
                number: 15,
                currency: "€",
                periodicity: "al mese (-15% annuale)"
              }
            },
            {
              title: "Business+",
              image: businessPlusPLanImage,
              subtitle: [
                "Hai un grande flusso di chiamate? Vuoi connettere MrCall al tuo centralino VOIP?"
              ],
              descriptionMain: {
                title: "Gestisci fino a 300 chiamate al mese.  Tutte le funzionalità di Business...",
                items: [
                ]
              },
              descriptionDiscover: {
                visible: false,
                title: "",
                moreInfoSentence: "...e molto altro ancora",
                items: [
                  {value: "Account VoIP (in aggiunta al numero)", fontWeight: "normal"},
                  {value: "Supporto per connessione al centralino VoIP", fontWeight: "normal"},
                  {value: "Possibilità di acquisto pacchetti chiamate oltre le 300 gestite dall'assistente", fontWeight: "normal"},
                  {value: "Intelligenza Artificiale: personalizzazione delle risposte per ogni problematica rilevata (a breve!)", fontWeight: "normal"},
                  {value: "Intelligenza Artificiale: comprensione dell'urgenza e notifiche personalizzate (a breve!)", fontWeight: "normal"},
                ]
              },
              pricing: {
                number: 25,
                currency: "€",
                periodicity: "al mese (-15% annuale)"
              }
            }
          ]
        },
        fourthSection: {
          title: "L'assistente digitale che fa per te",
          elements: [
            {
              image: individualImage,
              title: "Imprese individuali",
              sentences: [
                "Sei un idraulico, un elettricista?",
                "MrCall ti aiuta a gestire le chiamate dei tuoi clienti e non devi più interrompere il tuo lavoro per rispondere al telefono.",
                "Soddisfatti loro, tu più organizzato."
              ]
            },
            {
              image: practicesImage,
              title: "Studi professionali",
              sentences: [
                "Quando il telefono squilla in continuazione, e sai che ogni chiamata persa può diventare un problema",
                "MrCall ti aiuta rispondendo alle domande più semplici e inviandoti le trascrizioni di ogni messaggio."
              ]
            },
            {
              image: restaurantsImage,
              title: "Ristoranti, parrucchieri",
              sentences: [
                "Rispondere al telefono è fondamentale per il rapporto con i tuoi clienti.",
                "Ma a volte non puoi rispondere, e le richieste di prenotazione cadono nel vuoto.",
                "MrCall chiede giorno e ora, numero di persone e ti invia tutto."
              ]
            }
          ]
        },
        videContentSection: {
          title: "È successo anche a te? Adesso c'è MrCall",
          videos: [
            {
              url: "https://youtube.com/embed/9nn2DXrqqQk",
              title: "Stirlo Farmacista dovrebbe usare MrCall"
            },
            {
              url: "https://www.youtube.com/embed/2FN1PU8B9rA",
              title: "Il dentista Stirlo non conosce MrCall"
            },
            {
              url: "https://www.youtube.com/embed/bEO5ssj9pw8",
              title: "Anche l'idraulico Stirlo usa MrCall"
            },
            {
              url: "https://youtube.com/embed/hJiRMLlZn_8",
              title: "MrCall per i ristoranti: pizzeria Er' Nobile"
            },
          ]
        },
        testimonialsSection: {
          titlePart1: "Unisciti alle centinaia di aziende che hanno iniziato ad usare MrCall,",
          titlePart2: "il servizio che ha rivoluzionato la relazione con i loro clienti!",
          elements: [
            {
              image: testimonialIconImage,
              title: "Maurizio Costa",
              subtitle: "AD Studio Dentistico 3.0, Italia",
              sentences: [
                "Da 1 a 10 gli darei 11.",
                "MrCall libera il personale dipendente dall'assillo delle telefonate durante l'orario di lavoro.",
                "Tutti quelli che mi chiamano lo vogliono."
              ]
            },
            {
              image: testimonialIconImage,
              title: "Andrea Falchi",
              subtitle: "Consulente Innovation Business, Portogallo",
              sentences: [
                "Il mio MrCall è connesso sul numero italiano ma lo metterò anche sul numero portoghese.",
                "MrCall mi aiuta a capire se devo fare follow-up alle chiamate che ricevo."
              ]
            },
            {
              image: testimonialIconImage,
              title: "Francesco Marangolo",
              subtitle: "Digital strategist, Milano",
              sentences: [
                "Utilizzo MrCall da circa 7 mesi ed e’ importante sottolineare la grande differenza con segreterie o servizi simili,",
                "essendo animato da AI, capisce veramente le intenzioni dei clienti."
              ]
            },
            {
              image: testimonialIconImage,
              title: "Matteo Casini",
              subtitle: "Commercialista",
              sentences: [
                "MrCall riduce l'ansia. Infatti sono sicuro che in questo modo non mi perdo nessuna chiamata e ho la situazione sotto controllo, " +
                "anche quando non posso rispondere ..",
              ]
            },
            {
              image: testimonialIconImage,
              title: "Fabrizio Schiapacassa",
              subtitle: "Amministratore Condominio",
              sentences: [
                "MrCall tiene traccia di tutto e ti consente di offrire un servizio importante, anche durante gli orari di chiusura,",
                "i clienti chiamano e hanno la certezza che saranno ricontattati.",
              ]
            }
          ],
          callToActionText: "Provalo anche tu!"
        },
        sixthContent: {
          title: "Sei una software house e vuoi integrare l'assistente digitale ai tuoi servizi?",
          sentence1: "Offri un servizio di prenotazioni a parrucchieri e ristoranti? Installi servizi di telefonia VoIP?",
          sentence2: "Usa la stessa tecnologia che dà vita a MrCall per creare gli assistenti virtuali da connettere ai tuoi servizi.",
          sentence3: "Contattaci e troveremo la soluzione migliore insieme.",
          button: "Contattaci"
        },
        seventhContent: {
          title: "Sempre pronti a darti supporto",
          left: {
            title: "Assistenza MrCall",
            sentence1: "Contatta l'assistenza 24/7 per la risoluzione di problemi, per chiedere informazioni sul servizio",
            sentence2: "Chiamaci al numero +3902124128806 o scrivi a ",
            sentence3: "Ci attiveremo immediatamente!",
            mailTo: "support@mrcall.ai"
          },
          right: {
            title: "Consulenza MrCall",
            sentence1: "Se hai bisogno di una consulenza aziendale per la creazione di un assistente su misura,",
            sentence2: "contattaci e vedremo come aiutarti."
          },
          button: "Prenota un appuntamento"
        }
      }
    }

    return {
      pageTexts,
      responsiveOptionsTestimonials,
      responsiveOptionsUsecasesVideos
    }
  },
  methods: {
    gotoRoute: (route) => {
      window.location.href = process.env.VUE_APP_DASHBOARD_URL + process.env.VUE_APP_PUBLIC_PATH + route
    },
    goToWebSiteRoute: (route) => {
      router.push(route);
    },
    gotoElement: (id) => {
      var element = document.querySelector(id)
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    handleTestYourAssistantButton: () => {
      window.location.href = process.env.VUE_APP_DASHBOARD_URL + process.env.VUE_APP_PUBLIC_PATH + '/signup'
    },
    isSmallWidthDevice() {
      return window.innerWidth <= 640 ;
    }
  },
  created() {

  },
  mounted() {
    if(router.currentRoute.value.hash) {
      this.gotoElement(router.currentRoute.value.hash)
    } else {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/style/colors';
@import '../assets/style/fonts';

.p-button {
  font-family: Montserrat, serif;
  font-size: 1rem;
  font-weight: bold;

  color: @mrcall_white;
  background: @mrcall_blue;
  border: 1px solid @mrcall_blue;
  padding: 0.5rem 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 33px;
}

.p-inputtext {
  font-family: Montserrat, serif;
  font-size: 1rem;
  color: @mrcall_black;
  background: transparent;
  padding: 0.5rem 0.5rem;
  border: 1px solid @mrcall_light_grey_1;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 33px;
}

.with-left-border {
  border-left-width: 1px;
  padding-left: 2em;
  margin-left: 2em;
  border-left-color: @mrcall_black;
}

.left {
  margin-right: auto;
  text-align: left;
}

.right {
  margin-left: auto;
  text-align: right;
}

.center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.left{
  margin-right: auto;
  text-align: left;
}

#first-part {
  background-color: @mrcall_light_grey_1;
  border-width: 0 0 0.3em 0;
  border-color: transparent transparent @mrcall_blue;

  .p-button {
    font-weight: bold;
  }
}

#last-sentence {
  .italic-font ;
  font-weight: bold;
}

#first-content {
  display: flex;
  flex-flow: row wrap;
}

#second-content {
  display: flex;
  flex-flow: row wrap;
  margin: 2em;
  p {
    font-size: 1.2em;
    font-weight: normal;
    color: @mrcall_black;
  }
}

#second-part {
  background-color: @mrcall_white;

  border-width: 0 0 0.3em 0;
  border-color: transparent transparent @mrcall_blue
}

#features-part {
  background-color: @mrcall_light_grey_2;
  border-width: 0 0 0.3em 0;
  border-color: transparent transparent @mrcall_blue;

  .title {
    padding-bottom: 1em;
  }

  .features-content {
    display: flex;
    flex-flow: row wrap;
  }

  .features-text {
    margin: auto;
    font-size: 1.4em;
    color: @mrcall_blue;

    .feature-highligth {
      display: inline;
      font-weight: bold;
      font-size: 1em;
      color: @mrcall_blue;
    }

    .feature-normal {
      display: inline;
      font-weight: normal;
      font-size: 1em;
      color: @mrcall_black;
    }
  }

  .features-image {
    max-width: 400px;
    align-self: center;
    flex: 1;
    margin: auto;
    padding: 1em 0 1em 4em;
  }
}

#third-part {
  background-color: @mrcall_light_grey_2;

  .title-box {
    background-color: @mrcall_white;
  }

  .third-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    max-width: 90%;
    margin: auto;
  }

  .plan-title {
    display: inline;
    font-size: 2.5em;
    font-weight: bold;
    color: @mrcall_blue;
  }

  .plan-subtitle-sentence {
    font-size: 1.2em;
    font-weight: normal;
    color: @mrcall_black;
  }

  .plan-description-title {
    font-size: 1.2em;
    font-weight: bold;
    color: @mrcall_black;
  }

  .plan-description-item {
    font-size: 1.2em;
    color: @mrcall_black;
  }

  .more-information {
    font-size: 1.4em;
    font-weight: bold;
    color: @mrcall_black;
    text-decoration: underline;
    text-decoration-color: @mrcall_black;
  }

  .more-information {
    font-size: 1.4em;
    font-weight: bold;
    color: @mrcall_black;
    text-decoration: underline;
    text-decoration-color: @mrcall_black;
  }

  .price-number {
    font-size: 3em;
    font-weight: bold;
    color: @mrcall_black;

    .italic-font
  }

  .price-block {
    .center;
    padding-top: 1em;
  }

  .price-currency {
    font-size: 2em;
    font-weight: bold;
    color: @mrcall_black;

    .italic-font
  }

  .price-periodicity {
    font-size: 1.2em;
    font-weight: bold;
    color: @mrcall_black;

    .italic-font
  }

  .plan-card {
    background-color: @mrcall_white;
    border-style: none;
    border-radius: 20px;
    margin: 0.5em;
    padding: 1em;

    align-self: self-start;
    width: min-content;
    min-width: 300px ;
    max-width: 100%;
    flex: 1;
  }

  .p-button {
    font-weight: bold;
  }
}

#usecases-part {
  background-color: @mrcall_white;

  .title {
    font-size: 2em;
  }

  .sample-business {
    background-color: @mrcall_white;
    border-style: none;
    border-radius: 20px;
    margin: 0.5em;
    padding: 1em;

    align-self: flex-start;
    width: min-content;
    min-width: 200px ;
    max-width: 100%;
    flex: 1;
  }

  .usecases-content {
    display: flex;
    flex-flow: row wrap;

    justify-content: center;
  }

  .video-content{
    margin-top: 4em ;
    margin-bottom: 4em;

    display: flex;
    flex-flow: row wrap;

    .video-item {
      border-style: none;
      margin: auto;
      padding: 0.1em;
    }

    .video-section-title {
      margin: auto;
      flex: 1;
    }

    .video-section-sequence {
      padding-top: 0.3em;
      display: flex;
      flex: 1;
      @media screen and (max-width: 1440px) {
        flex-flow: row wrap;
        flex: 0.5;
      }

      min-width: 90%;
      margin: auto;
    }
  }

  .sample-business-title {
    .center ;
    font-size: 2em;
    font-weight: bold;
    color: @mrcall_black;
  }

  .sample-business-sentence {
    .center ;

    font-size: 1.2em;
    font-weight: normal;
    color: @mrcall_black;
  }
}

#testimonials-part {
  background-color: @mrcall_light_grey_2;

  .title {
    font-size: 2em;
    padding-bottom: 1em;
  }

  .p-button {
    font-weight: bold;
  }

  .witness {
    background-color: @mrcall_white;
    border-style: none;
    border-radius: 20px;
    margin: 5%;
    padding: 1em;
    height: 95%;
  }

  .testimonials-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    max-width: 90%;
    margin: auto;
  }

  .witness-section{
    margin-top: 1em;
    margin-bottom: 0;

    .witness-title {
      .left ;
      font-size: 1.2em;
      font-weight: bold;
      color: @mrcall_black;
    }

    .witness-subtitle {
      .left ;
      font-size: 1.0em;
      font-weight: normal;
      color: @mrcall_black;
    }
  }

  .witness-sentence {
    .left ;
    font-size: 1.4em;
    font-weight: normal;
    color: @mrcall_black;
  }
}

#sixth-part {
  background-color: @mrcall_white;

  .p-button {
    background: @mrcall_black;
    border: 1px solid @mrcall_black;
    font-weight: bold;
    color: @mrcall_orange;
  }

  .p-button:hover {
    background: @mrcall_orange;
    color: @mrcall_black;
  }

  #sixth-content {
    display: flex;
    flex-flow: row wrap;
    margin: 2em;
    p {
      font-size: 1.2em;
      font-weight: normal;
      color: @mrcall_black;
    }
  }
}

#seventh-part {
  background-color: @mrcall_light_grey_2;

  border-width: 0 0 1em 0;
  border-color: transparent transparent @mrcall_white;

  .p-button {
    font-weight: bold;
  }

  #seventh-content {
    display: flex;
    flex-flow: row wrap;
    padding: 2em;
    p {
      font-size: 1.2em;
      font-weight: normal;
      color: @mrcall_black;
    }
  }
}

.main-page-content-section {
  max-width: 80rem;
}

#footer {
  display: flex;
  background: @mrcall_black;
}
</style>
