<template>
  <div id="not-found">
    <h1 class="pb-4">{{pageTexts.it.pageNotFound}}</h1>
    <Button @click="redirectToMainPage()" :label="pageTexts.it.buttonLabel" />
  </div>
</template>

<script>
export default {
  data() {
    const pageTexts = {
      it: {
        pageNotFound: '404: Pagina non trovata',
        buttonLabel: 'Torna alla home page'
      }
    }

    return {
      pageTexts,
    }
  },
  methods: {
    redirectToMainPage: () => {
      window.location.href = '/'
    },
  },
}
</script>

<style lang="less" @scoped>
  #not-found {
    width: 100%;
    height: calc(100% - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
</style>
