<template>
  <div id="first-part">
    <div id="first-content" class="main-page-content-section" style="padding: 0;">
      <div style="width: 35% ; align-self: center; z-index: 100; margin-top: 10%; position: absolute;">
        <div style="height: 100%;">
          <p class="title-white center">{{ pageTexts.it.firstContent.title }}</p>
        </div>
      </div>
      <div style="width: 100% ; min-width: 300px ; margin-top: auto ; ">
        <img style="width: 100% ;" src="@/assets/images/mrcall/aboutus/hero_banner.png"/>
      </div>
    </div>
  </div>
  <div id="second-part">
    <div class="main-page-content-section">
      <p class="title" style="text-align: center ;">{{ pageTexts.it.secondContent.title }}</p>
      <div id="second-content">
        <div style="width: 30% ; min-width: 200px ; align-self: center;">
          <img style="width: 90%;" src="@/assets/images/mrcall/littleman/mission_doctor.svg"/>
        </div>
        <div class="with-left-border" style="width: fit-content; align-self: center; flex: 1;">
          <p style="font-weight: bold;">{{ pageTexts.it.secondContent.sentence1 }}</p>
        </div>
      </div>
    </div>
  </div>
  <div id="founders-part">
    <div class="main-page-content-section">
      <p class="title center">{{ pageTexts.it.fourthSection.title }}</p>
      <br>
      <div style="margin-top: 0; margin-left: 10%; margin-right: 10%; margin-bottom: 0% ;">
        <p class="founders-cv-sentence" v-for="sentence in pageTexts.it.fourthSection.introductionSentences" :key="sentence">
          {{sentence}}
        </p>
      </div>
      <div class="usecases-content">
        <div class="founder-profile center" v-for="founderProfile in pageTexts.it.fourthSection.elements" :key="founderProfile">
          <p style="padding-top: 1em; padding-bottom: 1em;" class="founder-name">{{founderProfile.title}}</p>
          <p class="founders-cv-sentence" v-for="sentence in founderProfile.sentences" :key="sentence">
            {{sentence}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div id="footer">
    <FooterAboutUs/>
  </div>
</template>

<script>
import FooterAboutUs from "@/components/FooterAboutUs";
import router from "@/router";
import store from "@/store"
import {computed} from "vue";

export default {
  components: { FooterAboutUs },
  setup: () => {
    return {
    }
  },
  data: () => {
    const responsiveOptionsFounders = [
      {
        breakpoint: '8192px',
        numVisible: 3,
        numScroll: 1
      },
      {
        breakpoint: '960px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '600px',
        numVisible: 1,
        numScroll: 1
      }
    ]

    const responsiveOptionsUsecasesVideos = [
      {
        breakpoint: '8192px',
        numVisible: 3,
        numScroll: 1
      },
      {
        breakpoint: '1200px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '600px',
        numVisible: 1,
        numScroll: 1
      }
    ]

    const pageTexts = {
      it: {
        selectPlanButtonText: "Comincia gratis la tua prova",
        firstContent: {
          title: "Chi siamo?"
        },
        secondContent: {
          title: "La nostra missione",
          sentence1: "Offrire alle piccole e medie aziende assistenti digitali animati da intelligenza artificiale, " +
              "di facile attivazione, a basso investimento, capaci di automatizzare i task più ripetitivi e " +
              "rendere più efficienti i canali comunicativi con i clienti."
        },
        fourthSection: {
          title: "I Fondatori",
          introductionSentences: [
            "I due fondatori, dopo diverse esperienze di successo in start up internazionali di speech analytics, " +
            "hanno fondato HahnBanach, investendo personalmente per finanziare interamente lo sviluppo " +
            "del software di MrCall e le prime fasi di commercializzazione."
          ],
          elements: [
            {
              //image: marioPhoto,
              title: "Mario Alemi",
              sentences: [
                "Mario ha costruito la sua carriera prima in campo accademico e poi in start up di successo " +
                "dove il suo background scientifico ha permesso di sviluppare servizi altamente innovativi.",
                "PhD in Elementary Particle Physics, carriera accademica al CERN, professore per Mathematical " +
                "Methods alla Camera di Commercio e Industria di Parigi e Londra, Co-founder di Your.MD " +
                "(Chief Data Officer) e GetJenny (Chief Scientist).",
                "Your.MD ha ricevuto $47m di fondi ed è stata menzionata dal governo del Regno Unito " +
                "come eccellenza per l’uso dell’AI nel campo della salute. GetJenny ha ricevuto fondi per " +
                "oltre 2m. di Euro ed è stata venduta nel 2021 a LeadDesk, con un fatturato e un EBITDA " +
                "rispettivamente di €1.5M e €500,000."
              ]
            },
            {
              //image: angeloPhoto,
              title: "Angelo Leto",
              sentences: [
                "Angelo ha cominciato il suo percorso in campo accademico per poi occuparsi di " +
                "machine learning applicato all’imaging medicale e al linguaggio naturale." +
                "Ha cominciato la sua carriera all’International Center for Theoretical Physics (ICTP) " +
                "di Trieste, dove ha insegnato al Master in High Performance Computing.",
                "Tra le sue collaborazioni, l'italiana CELI come chief developer in ambito " +
                "Natural Language Processing, introducendo tecniche di machine learning e processi incrementando " +
                "l'efficienza nel processo di sviluppo. CELI è stata acquisita nel 2017 da H-Farm.",
                "Co-fondatore di GetJenny dove, come Chief Technology Officer, ha guidato il team di sviluppo."
              ]
            }
          ]
        },
      }
    }

    return {
      userVerified: computed(() => store.state.userVerified),
      authIsReady: computed(() => store.state.authIsReady),
      pageTexts,
      responsiveOptionsFounders,
      responsiveOptionsUsecasesVideos
    }
  },
  methods: {
    gotoRoute: (route) => {
      router.push(route)
    },
    gotoElement: (id) => {
      var element = document.querySelector(id)
      var top = element.offsetTop;
      window.scrollTo(0, top);
    }
  },
  mounted() {
    if(router.currentRoute.value.hash) {
      this.gotoElement(router.currentRoute.value.hash)
    } else {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/style/colors';
@import '../assets/style/fonts';

.p-button {
  font-family: Montserrat, serif;
  font-size: 1rem;
  font-weight: bold;

  color: @mrcall_white;
  background: @mrcall_blue;
  border: 1px solid @mrcall_blue;
  padding: 0.5rem 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 33px;
}

.p-inputtext {
  font-family: Montserrat, serif;
  font-size: 1rem;
  color: @mrcall_black;
  background: transparent;
  padding: 0.5rem 0.5rem;
  border: 1px solid @mrcall_light_grey_1;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 33px;
}

.with-left-border {
  border-left-width: 1px;
  padding-left: 2em;
  margin-left: 2em;
  border-left-color: @mrcall_black;
}

.left {
  margin-right: auto;
  text-align: left;
}

.right {
  margin-left: auto;
  text-align: right;
}

.center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.left{
  margin-right: auto;
  text-align: left;
}

#first-part {
  background-color: @mrcall_light_grey_1;

  border-width: 0 0 0.3em 0;
  border-color: transparent transparent @mrcall_blue;

  .p-button {
    font-weight: bold;
  }
}

#last-sentence {
  .italic-font ;
  font-weight: bold;
}

#first-content {

}

#second-content {
  display: flex;
  flex-flow: row wrap;
  margin: 2em;
  p {
    font-size: 1.2em;
    font-weight: normal;
    color: @mrcall_black;
  }
}

#second-part {
  background-color: @mrcall_light_grey_2;

  border-width: 0 0 0.3em 0;
  border-color: transparent transparent @mrcall_blue
}

#founders-part {
  background-color: @mrcall_white;

  .title {
    font-size: 2em;
  }

  .founder-profile {
    background-color: @mrcall_white;
    border-style: none;
    border-radius: 20px;
    margin: 0.5em;
    padding: 1em;

    align-self: flex-start;
    width: min-content;
    min-width: 200px ;
    max-width: 100%;
    flex: 1;
  }

  .usecases-content {
    display: flex;
    flex-flow: row wrap;

    justify-content: center;
  }

  .founder-name {
    .center ;
    font-size: 2em;
    font-weight: bold;
    color: @mrcall_black;
  }

  .founders-cv-sentence {
    .center ;

    font-size: 1.2em;
    font-weight: normal;
    color: @mrcall_black;
  }
}
</style>
