<template>
  <div class="main-page-content-section">
    <div style="  display: flex; flex-flow: row wrap; justify-content: space-around;">
      <div class="calendly-inline-widget" data-url="https://calendly.com/mrcall_ai?primary_color=0068ff" style="width: fit-content ; min-width:320px;height:630px;"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CallBooking",
  setup() {
  },
  data() {
    let language = "it"
    return {
      language,
    }
  },
  created() {
  },
  mounted() {
    let externalScript = document.createElement('script')
    externalScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    document.head.appendChild(externalScript)
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
@import '../assets/style/colors';
@import '../assets/style/fonts';

</style>